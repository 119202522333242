import apiClient from 'services/axios'

export async function getDistributorGroup(parameter) {
  return apiClient
    .get('/user/distributor-group/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createDistributorGroup(parameter = {}) {
  return apiClient
    .get('/user/distributor-group/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeDistributorGroup(values) {
  return apiClient
    .post('/user/distributor-group/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editDistributorGroup(id, parameter = {}) {
  return apiClient
    .get(`/user/distributor-group/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateDistributorGroup(id, values) {
  return apiClient
    .post(`/user/distributor-group/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteDistributorGroup(id) {
  return apiClient
    .delete(`/user/distributor-group/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
