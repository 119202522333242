import apiClient from 'services/axios'

export async function search(parameter) {
  return apiClient
    .get('/user/product-dist-group/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function create(parameter = {}) {
  return apiClient
    .get('/user/product-dist-group/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function store(values) {
  return apiClient
    .post('/user/product-dist-group/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function edit(id, parameter = {}) {
  return apiClient
    .get(`/user/product-dist-group/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function update(id, values) {
  return apiClient
    .post(`/user/product-dist-group/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteData(id, parameter = {}) {
  return apiClient
    .delete(`/user/product-dist-group/${id}?dist_group_id=${parameter.dist_group_id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function filter(parameter = {}) {
  return apiClient
    .get('/user/product-dist-group/filter', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadFile(values) {
  return apiClient
    .post(`/user/product-dist-group-temp/upload-file`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function download(parameter) {
  return apiClient
    .get('/user/product-dist-group-temp/download-file', {
      params: parameter,
      responseType: 'blob',
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function reminder(values) {
  return apiClient
    .post(`/user/product-dist-group/reminder-approval`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
