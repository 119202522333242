const actions = {
  SET_STATE: 'report-ach-by-brand-range-channel-sub/SET_STATE',
  SEARCH: 'report-ach-by-brand-range-channel-sub/SEARCH',
  FILTER: 'report-ach-by-brand-range-channel-sub/FILTER',
  CANCEL: 'report-ach-by-brand-range-channel-sub/CANCEL',
  DOWNLOAD: 'report-ach-by-brand-range-channel-sub/DOWNLOAD',
  REPROCESS_FILE: 'report-ach-by-brand-range-channel-sub/REPROCESS_FILE',
  LAST_UPDATED: 'report-ach-by-brand-range-channel-sub/LAST_UPDATED',
}

export default actions
