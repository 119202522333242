import apiClient from 'services/axios'

export async function progressProduct(parameter) {
  return apiClient
    .get('/user/dashboard/progress-product-approval', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function progressProductDist(parameter) {
  return apiClient
    .get('/user/dashboard/progress-product-dist-approval', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function progressProductDistGroup(parameter) {
  return apiClient
    .get('/user/dashboard/progress-product-dist-group-approval', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function progressProductDistBlock(parameter) {
  return apiClient
    .get('/user/dashboard/progress-product-dist-block-approval', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function progressOutlet(parameter) {
  return apiClient
    .get('/user/dashboard/progress-outlet-approval', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function progressOutletLatLngImage(parameter) {
  return apiClient
    .get('/user/dashboard/progress-outlet-latlng-image-approval', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function progressOutletWithSpg(parameter) {
  return apiClient
    .get('/user/dashboard/progress-outlet-with-spg-approval', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function progressOutletDist(parameter) {
  return apiClient
    .get('/user/dashboard/progress-outlet-dist-approval', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function progressOutletDistBlock(parameter) {
  return apiClient
    .get('/user/dashboard/progress-outlet-dist-block-approval', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function progressOutletSales(parameter) {
  return apiClient
    .get('/user/dashboard/progress-outlet-sales-approval', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function progressOutletSpg(parameter) {
  return apiClient
    .get('/user/dashboard/progress-outlet-spg-approval', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function progressOutletSalesSpv(parameter) {
  return apiClient
    .get('/user/dashboard/progress-outlet-sales-spv-approval', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function progressOutletMd(parameter) {
  return apiClient
    .get('/user/dashboard/progress-outlet-md-approval', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function progressOutletTl(parameter) {
  return apiClient
    .get('/user/dashboard/progress-outlet-tl-approval', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function progressOutletTlSpv(parameter) {
  return apiClient
    .get('/user/dashboard/progress-outlet-tl-spv-approval', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function progressTargetSales(parameter) {
  return apiClient
    .get('/user/dashboard/progress-target-secondary-sales-approval', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function progressTargetPrimarySales(parameter) {
  return apiClient
    .get('/user/dashboard/progress-target-primary-sales-approval', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function progressTargetDist(parameter) {
  return apiClient
    .get('/user/dashboard/progress-target-secondary-dist-approval', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function progressTargetBrand(parameter) {
  return apiClient
    .get('/user/dashboard/progress-target-secondary-brand-approval', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function progressTargetChannelSub(parameter) {
  return apiClient
    .get('/user/dashboard/progress-target-secondary-channel-sub-approval', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function progressTargetSalesLcs(parameter) {
  return apiClient
    .get('/user/dashboard/progress-target-secondary-sales-lcs-approval', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function progressTargetSalesCb(parameter) {
  return apiClient
    .get('/user/dashboard/progress-target-secondary-sales-cb-approval', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function progressTargetSalesRental(parameter) {
  return apiClient
    .get('/user/dashboard/progress-target-secondary-sales-rental-approval', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function progressTargetSalesOutlet(parameter) {
  return apiClient
    .get('/user/dashboard/progress-target-secondary-sales-outlet-approval', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function progressTargetSalesSpg(parameter) {
  return apiClient
    .get('/user/dashboard/progress-target-secondary-sales-spg-approval', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function progressBalanceSummary(parameter) {
  return apiClient
    .get('/user/dashboard/progress-balance-summary-approval', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function progressDistributor(parameter) {
  return apiClient
    .get('/user/dashboard/progress-distributor-approval', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function progressCertificateGtOnline(parameter) {
  return apiClient
    .get('/user/dashboard/progress-certificate-gt-online-approval', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listProduct(id, parameter = {}) {
  return apiClient
    .get(`/user/dashboard/list-product-approval/${id}`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listProductDist(id, parameter = {}) {
  return apiClient
    .get(`/user/dashboard/list-product-dist-approval/${id}`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listProductDistGroup(id, parameter = {}) {
  return apiClient
    .get(`/user/dashboard/list-product-dist-group-approval/${id}`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listProductDistBlock(id, parameter = {}) {
  return apiClient
    .get(`/user/dashboard/list-product-dist-block-approval/${id}`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listOutlet(id, parameter = {}) {
  return apiClient
    .get(`/user/dashboard/list-outlet-approval/${id}`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listOutletLatLngImage(parameter = {}) {
  return apiClient
    .get(`/user/dashboard/list-outlet-latlng-image-approval`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listOutletWithSpg(id, parameter = {}) {
  return apiClient
    .get(`/user/dashboard/list-outlet-with-spg-approval/${id}`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listOutletDist(id, parameter = {}) {
  return apiClient
    .get(`/user/dashboard/list-outlet-dist-approval/${id}`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listOutletDistNew(id, parameter = {}) {
  return apiClient
    .get(`/user/dashboard/list-outlet-dist-approval/${id}/new-outlet`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listOutletDistBlock(id, parameter = {}) {
  return apiClient
    .get(`/user/dashboard/list-outlet-dist-block-approval/${id}`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listOutletSales(id, parameter = {}) {
  return apiClient
    .get(`/user/dashboard/list-outlet-sales-approval/${id}`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listOutletSpg(id, parameter = {}) {
  return apiClient
    .get(`/user/dashboard/list-outlet-spg-approval/${id}`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listOutletSalesSpv(id, parameter = {}) {
  return apiClient
    .get(`/user/dashboard/list-outlet-sales-spv-approval/${id}`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listOutletMd(id, parameter = {}) {
  return apiClient
    .get(`/user/dashboard/list-outlet-md-approval/${id}`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listOutletTl(id, parameter = {}) {
  return apiClient
    .get(`/user/dashboard/list-outlet-tl-approval/${id}`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listOutletTlSpv(id, parameter = {}) {
  return apiClient
    .get(`/user/dashboard/list-outlet-tl-spv-approval/${id}`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listBalanceSummary(id, parameter = {}) {
  return apiClient
    .get(`/user/dashboard/list-balance-summary-approval/${id}`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listDistributor(id, parameter = {}) {
  return apiClient
    .get(`/user/dashboard/list-distributor-approval/${id}`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function outletFilterArea(id, parameter = {}) {
  return apiClient
    .get(`/user/dashboard/filter-outlet-approval/${id}`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listTargetPrimarySales(id, parameter = {}) {
  return apiClient
    .get(`/user/dashboard/list-target-primary-sales-approval/${id}`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listTargetDist(id, parameter = {}) {
  return apiClient
    .get(`/user/dashboard/list-target-secondary-dist-approval/${id}`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listTargetSales(id, parameter = {}) {
  return apiClient
    .get(`/user/dashboard/list-target-secondary-sales-approval/${id}`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listTargetBrand(id, parameter = {}) {
  return apiClient
    .get(`/user/dashboard/list-target-secondary-brand-approval/${id}`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listTargetChannelSub(id, parameter = {}) {
  return apiClient
    .get(`/user/dashboard/list-target-secondary-channel-sub-approval/${id}`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listTargetSalesLcs(id, parameter = {}) {
  return apiClient
    .get(`/user/dashboard/list-target-secondary-sales-lcs-approval/${id}`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listTargetSalesCb(id, parameter = {}) {
  return apiClient
    .get(`/user/dashboard/list-target-secondary-sales-cb-approval/${id}`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listTargetSalesRental(id, parameter = {}) {
  return apiClient
    .get(`/user/dashboard/list-target-secondary-sales-rental-approval/${id}`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listTargetSalesOutlet(id, parameter = {}) {
  return apiClient
    .get(`/user/dashboard/list-target-secondary-sales-outlet-approval/${id}`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listTargetSalesSpg(id, parameter = {}) {
  return apiClient
    .get(`/user/dashboard/list-target-secondary-sales-spg-approval/${id}`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listCertificateGtOnline(id, parameter = {}) {
  return apiClient
    .get(`/user/dashboard/list-certificate-gt-online-approval/${id}`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
