const actions = {
  SET_STATE: 'dunning-letter-local/SET_STATE',
  LOAD_DUNNING_LETTER: 'dunning-letter-local/LOAD_DUNNING_LETTER',
  CANCEL_DUNNING_LETTER: 'dunning-letter-local/CANCEL_DUNNING_LETTER',
  SEND_EMAIL_DUNNING_LETTER: 'dunning-letter-local/SEND_EMAIL_DUNNING_LETTER',
  UPLOAD_FILE: 'dunning-letter-local/UPLOAD_FILE',
  DELETE_DUNNING_LETTER: 'dunning-letter-local/DELETE_DUNNING_LETTER',
}

export default actions
