const actions = {
  SET_STATE: 'dunning-letter-interco/SET_STATE',
  LOAD_DUNNING_LETTER: 'dunning-letter-interco/LOAD_DUNNING_LETTER',
  CANCEL_DUNNING_LETTER: 'dunning-letter-interco/CANCEL_DUNNING_LETTER',
  SEND_EMAIL_DUNNING_LETTER: 'dunning-letter-interco/SEND_EMAIL_DUNNING_LETTER',
  UPLOAD_FILE: 'dunning-letter-interco/UPLOAD_FILE',
  DELETE_DUNNING_LETTER: 'dunning-letter-interco/DELETE_DUNNING_LETTER',
}

export default actions
