const actions = {
    SET_STATE: 'target-npd/SET_STATE',
    SEARCH: 'target-npd/SEARCH',
    CANCEL: 'target-npd/CANCEL',
    CREATE: 'target-npd/CREATE',
    EDIT: 'target-npd/EDIT',
    STORE: 'target-npd/STORE',
    UPDATE: 'target-npd/UPDATE',
    DELETE: 'target-npd/DELETE',
    FILTER: 'target-npd/FILTER',
    UPLOAD_FILE: 'target-npd/UPLOAD_FILE',
    LIST_PRODUCT: 'target-npd/LIST_PRODUCT',
    LIST_CHANNEL_SUB: 'target-npd/LIST_CHANNEL_SUB',
  }
  
  export default actions
  