import apiClient from 'services/axios'

export async function getTargetNpd(parameter) {
  return apiClient
    .get('/user/target-npd/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createTargetNpd(parameter = {}) {
  return apiClient
    .get('/user/target-npd/create', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function storeTargetNpd(values) {
  return apiClient
    .post('/user/target-npd/store', values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function editTargetNpd(id, parameter = {}) {
  return apiClient
    .get(`/user/target-npd/${id}/edit`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateTargetNpd(id, values) {
  return apiClient
    .post(`/user/target-npd/${id}/update`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function deleteTargetNpd(id) {
  return apiClient
    .delete(`/user/target-npd/${id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function filterData(parameter = {}) {
  return apiClient
    .get('/user/target-npd/filter', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadFile(values) {
  return apiClient
    .post(`/user/target-npd/upload-file`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listProduct(parameter = {}) {
  return apiClient
    .get(`/user/target-npd/list-product`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function listChannelSub(parameter = {}) {
  return apiClient
    .get(`/user/target-npd/list-channel-sub`, {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
