const actions = {
  SET_STATE: 'dashboard/SET_STATE',
  PROGRESS_PRODUCT: 'dashboard/PROGRESS_PRODUCT',
  PROGRESS_PRODUCT_DIST: 'dashboard/PROGRESS_PRODUCT_DIST',
  PROGRESS_PRODUCT_DIST_GROUP: 'dashboard/PROGRESS_PRODUCT_DIST_GROUP',
  PROGRESS_PRODUCT_DIST_BLOCK: 'dashboard/PROGRESS_PRODUCT_DIST_BLOCK',
  PROGRESS_OUTLET: 'dashboard/PROGRESS_OUTLET',
  PROGRESS_OUTLET_LATLNG_IMAGE: 'dashboard/PROGRESS_OUTLET_LATLNG_IMAGE',
  PROGRESS_OUTLET_WITH_SPG: 'dashboard/PROGRESS_OUTLET_WITH_SPG',
  PROGRESS_OUTLET_DIST: 'dashboard/PROGRESS_OUTLET_DIST',
  PROGRESS_OUTLET_DIST_BLOCK: 'dashboard/PROGRESS_OUTLET_DIST_BLOCK',
  PROGRESS_OUTLET_SALES: 'dashboard/PROGRESS_OUTLET_SALES',
  PROGRESS_OUTLET_SPG: 'dashboard/PROGRESS_OUTLET_SPG',
  PROGRESS_OUTLET_SALES_SPV: 'dashboard/PROGRESS_OUTLET_SALES_SPV',
  PROGRESS_OUTLET_MD: 'dashboard/PROGRESS_OUTLET_MD',
  PROGRESS_OUTLET_TL: 'dashboard/PROGRESS_OUTLET_TL',
  PROGRESS_OUTLET_TL_SPV: 'dashboard/PROGRESS_OUTLET_TL_SPV',
  PROGRESS_TARGET_PRIMARY_SALES: 'dashboard/PROGRESS_TARGET_PRIMARY_SALES',
  PROGRESS_TARGET_SALES: 'dashboard/PROGRESS_TARGET_SALES',
  PROGRESS_TARGET_DIST: 'dashboard/PROGRESS_TARGET_DIST',
  PROGRESS_TARGET_BRAND: 'dashboard/PROGRESS_TARGET_BRAND',
  PROGRESS_TARGET_CHANNEL_SUB: 'dashboard/PROGRESS_TARGET_CHANNEL_SUB',
  PROGRESS_TARGET_SALES_LCS: 'dashboard/PROGRESS_TARGET_SALES_LCS',
  PROGRESS_TARGET_SALES_CB: 'dashboard/PROGRESS_TARGET_SALES_CB',
  PROGRESS_TARGET_SALES_RENTAL: 'dashboard/PROGRESS_TARGET_SALES_RENTAL',
  PROGRESS_TARGET_SALES_OUTLET: 'dashboard/PROGRESS_TARGET_SALES_OUTLET',
  PROGRESS_TARGET_SALES_SPG: 'dashboard/PROGRESS_TARGET_SALES_SPG',
  PROGRESS_BALANCE_SUMMARY: 'dashboard/PROGRESS_BALANCE_SUMMARY',
  PROGRESS_DISTRIBUTOR: 'dashboard/PROGRESS_DISTRIBUTOR',
  PROGRESS_CERTIFICATE_GT_ONLINE: 'dashboard/PROGRESS_CERTIFICATE_GT_ONLINE',

  LIST_PRODUCT: 'dashboard/LIST_PRODUCT',
  LIST_PRODUCT_DIST: 'dashboard/LIST_PRODUCT_DIST',
  LIST_PRODUCT_DIST_GROUP: 'dashboard/LIST_PRODUCT_DIST_GROUP',
  LIST_PRODUCT_DIST_BLOCK: 'dashboard/LIST_PRODUCT_DIST_BLOCK',
  LIST_OUTLET: 'dashboard/LIST_OUTLET',
  LIST_OUTLET_LATLNG_IMAGE: 'dashboard/LIST_OUTLET_LATLNG_IMAGE',
  LIST_OUTLET_WITH_SPG: 'dashboard/LIST_OUTLET_WITH_SPG',
  LIST_OUTLET_DIST: 'dashboard/LIST_OUTLET_DIST',
  LIST_OUTLET_DIST_NEW: 'dashboard/LIST_OUTLET_DIST_NEW',
  LIST_OUTLET_DIST_BLOCK: 'dashboard/LIST_OUTLET_DIST_BLOCK',
  LIST_OUTLET_SALES: 'dashboard/LIST_OUTLET_SALES',
  LIST_OUTLET_SPG: 'dashboard/LIST_OUTLET_SPG',
  LIST_OUTLET_SALES_SPV: 'dashboard/LIST_OUTLET_SALES_SPV',
  LIST_OUTLET_MD: 'dashboard/LIST_OUTLET_MD',
  LIST_OUTLET_TL: 'dashboard/LIST_OUTLET_TL',
  LIST_OUTLET_TL_SPV: 'dashboard/LIST_OUTLET_TL_SPV',
  LIST_TARGET_PRIMARY_SALES: 'dashboard/LIST_TARGET_PRIMARY_SALES',
  LIST_TARGET_DIST: 'dashboard/LIST_TARGET_DIST',
  LIST_TARGET_SALES: 'dashboard/LIST_TARGET_SALES',
  LIST_TARGET_BRAND: 'dashboard/LIST_TARGET_BRAND',
  LIST_TARGET_CHANNEL_SUB: 'dashboard/LIST_TARGET_CHANNEL_SUB',
  LIST_TARGET_SALES_LCS: 'dashboard/LIST_TARGET_SALES_LCS',
  LIST_TARGET_SALES_CB: 'dashboard/LIST_TARGET_SALES_CB',
  LIST_TARGET_SALES_RENTAL: 'dashboard/LIST_TARGET_SALES_RENTAL',
  LIST_TARGET_SALES_OUTLET: 'dashboard/LIST_TARGET_SALES_OUTLET',
  LIST_TARGET_SALES_SPG: 'dashboard/LIST_TARGET_SALES_SPG',
  LIST_BALANCE_SUMMARY: 'dashboard/LIST_BALANCE_SUMMARY',
  LIST_DISTRIBUTOR: 'dashboard/LIST_DISTRIBUTOR',
  LIST_CERTIFICATE_GT_ONLINE: 'dashboard/LIST_CERTIFICATE_GT_ONLINE',

  OUTLET_FILTER_AREA: 'dashboard/OUTLET_FILTER_AREA',

  RESET_MODAL_PROGRESS: 'dashboard/RESET_MODAL_PROGRESS',

  CANCEL: 'dashboard/CANCEL',
}

export default actions
