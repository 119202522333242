import apiClient from 'services/axios'

export async function getDunningLetter(parameter) {
  return apiClient
    .get('/user/dunning-letter-local/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function uploadFile(values) {
  return apiClient
    .post(`/user/dunning-letter-local/upload-file`, values)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}


export async function deleteDunningLetter() {
  return apiClient
    .delete(`/user/dunning-letter-local/delete`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function sendEmailDunningLetter(values) {
    return apiClient
      .post(`/user/dunning-letter-local/send-email`, values)
      .then(response => ({ response }))
      .catch(error => ({ error }))
  }
