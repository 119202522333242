const actions = {
  SET_STATE: 'distributor-group/SET_STATE',
  SEARCH: 'distributor-group/SEARCH',
  CANCEL: 'distributor-group/CANCEL',
  CREATE: 'distributor-group/CREATE',
  EDIT: 'distributor-group/EDIT',
  STORE: 'distributor-group/STORE',
  UPDATE: 'distributor-group/UPDATE',
  DELETE: 'distributor-group/DELETE',
}

export default actions
