import apiClient from 'services/axios'

export async function getDunningLetterLog(parameter) {
  return apiClient
    .get('/user/dunning-letter-local-log/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function getDetailDunningLetterLog(id, parameter = {}) {
    parameter.no_surat = id
    return apiClient
      .get(`/user/dunning-letter-local-log/${id}/search-detail`, {
        params: parameter,
      })
      .then(response => ({ response }))
      .catch(error => ({ error }))
  }