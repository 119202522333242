const actions = {
    SET_STATE: 'product-dist-group/SET_STATE',
    SEARCH: 'product-dist-group/SEARCH',
    CANCEL: 'product-dist-group/CANCEL',
    CREATE: 'product-dist-group/CREATE',
    EDIT: 'product-dist-group/EDIT',
    STORE: 'product-dist-group/STORE',
    UPDATE: 'product-dist-group/UPDATE',
    DELETE: 'product-dist-group/DELETE',
    FILTER: 'product-dist-group/FILTER',
    UPLOAD_FILE: 'product-dist-group/UPLOAD_FILE',
    DOWNLOAD: 'product-dist-group/DOWNLOAD',
    REMINDER: 'product-dist-group/REMINDER',
  }
  
  export default actions
  