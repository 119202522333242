const actions = {
  SET_STATE: 'outlet-with-spg-active/SET_STATE',
  SEARCH: 'outlet-with-spg-active/SEARCH',
  CANCEL: 'outlet-with-spg-active/CANCEL',
  CANCEL_LIST_OUTLET: 'outlet-with-spg-active/CANCEL_LIST_OUTLET',
  CREATE: 'outlet-with-spg-active/CREATE',
  STORE: 'outlet-with-spg-active/STORE',
  EDIT: 'outlet-with-spg-active/EDIT',
  UPDATE: 'outlet-with-spg-active/UPDATE',
  FILTER: 'outlet-with-spg-active/FILTER',
  UPLOAD_FILE: 'outlet-with-spg-active/UPLOAD_FILE',
  LIST_OUTLET: 'outlet-with-spg-active/LIST_OUTLET',
  REMINDER: 'outlet-with-spg-active/REMINDER',
}

export default actions
