import apiClient from 'services/axios'

export async function search(parameter) {
  return apiClient
    .get('/user/product-dist-group-temp/search', {
      params: parameter,
    })
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function reset(parameter = {}) {
  return apiClient
    .delete(`/user/product-dist-group-temp/reset?dist_group_id=${parameter.dist_group_id}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function save(parameter = {}) {
  return apiClient
    .post(`/user/product-dist-group-temp/save`, parameter)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
